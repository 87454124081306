@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

.project{
  height: 100px;
  width: 100px;
  background-color: red;
}