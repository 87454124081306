.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header { 
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #3605e72d;
}

.App-link {
  color: #61dafb;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
}

.skill-item {
  display: inline-block;
  margin-right: 1rem; /* Adjust spacing between skills */
  animation: animateSkill 10s linear infinite; /* Adjust duration as needed */
}

.project{
  height: 100px;
  width: 100px;
  background-color: red;
}

@keyframes animateSkill {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
